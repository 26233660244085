<template>
  <v-container fluid style="overflow-y: auto">
    <v-row no-gutters>
      <v-col :cols="control.config.cols" v-for="spot in spots" :key="spot.uuid">
        <v-card elevation="0" color="transparent">
          <div
            class="d-flex"
            style="user-select: none"
            :style="cardStyle(spot)"
          >
            <span
              v-text="spot.name"
              :style="{
                color:
                  spot.alarm == 0 || spot.alarm == 7 || spot.alarm == 8
                    ? control.style.color
                    : control.config.alarmColor,
              }"
            ></span>
            <v-spacer></v-spacer>
            <span
              v-text="spot.value"
              :style="{
                color:
                  spot.alarm == 0 || spot.alarm == 7 || spot.alarm == 8
                    ? control.style.color
                    : control.config.alarmColor,
              }"
            ></span>
            <span
              v-text="spot.unit"
              :style="{
                color:
                  spot.alarm == 0 || spot.alarm == 7 || spot.alarm == 8
                    ? control.style.color
                    : control.config.alarmColor,
              }"
            ></span>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import proto from "../utils/proto";
import base64 from "../utils/base64";
import client from "../utils/client";
export default {
  props: { control: Object, isView: Boolean },
  data() {
    return {
      spots: [],
    };
  },
  watch: {
    "control.config.deviceId": {
      handler() {
        this.getSpots();
      },
    },
    "control.config.spotNumbers": {
      handler() {
        this.getSpots();
      },
    },
  },
  mounted() {
    this.getSpots();
    if (this.isView) {
      client.$on("chnState", this.chnState);
      client.$on("brocast", this.brocast);
    }
  },
  beforeDestroy() {
    if (this.isView) {
      client.$off("chnState", this.chnState);
      client.$off("brocast", this.brocast);
    }
  },
  methods: {
    cardStyle(spot) {
      let result = JSON.parse(JSON.stringify(this.control.style));
      let normal = spot.alarm == 0 || spot.alarm == 7 || spot.alarm == 8;
      if (!normal) {
        result["border-color"] = this.control.config.alarmBorderColor;
        result["background-color"] = this.control.config.alarmBackgroundColor;
        result["box-shadow"] = this.control.config.alarmBoxShadow;
      }
      return result;
    },
    chnState(chnState) {
      if (chnState == proto.ChnState.ready) {
        this.getStatus();
        this.getData();
      }
    },
    async getSpots() {
      let spotDict = {};
      client.$emit(
        "startBusy",
        "ZhtSpotList.getSpots",
        "正在获取测点,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.spotMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
          },
          act: 1,
          devices: [this.control.config.deviceId],
        });
        if (res.spots && res.spots.length) {
          res.spots.sort((a, b) => {
            return a.no > b.no ? 1 : -1;
          });
          for (let i = 0; i < res.spots.length; i++) {
            let spot = res.spots[i];
            spot.valueDict = {};
            spot.alarm = 0;
            spot.value = "/";
            spotDict[`${spot.no}`] = spot;
            if (spot.stateenum) {
              let kp = spot.stateenum.split(";");
              for (let i in kp) {
                let kv = kp[i].split(":");
                if (kv.length == 2) {
                  spot.valueDict[`${kv[0]}`] = kv[1];
                }
              }
            }
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ZhtSpotList.getSpots");
      let results = [];
      if (Object.keys(spotDict).length == 0) {
        let baseName = "测点";
        for (let i = 1; i <= 24; i++) {
          spotDict[`${i}`] = {
            uuid: `${baseName}-${i}`,
            no: i,
            name: `${baseName}-${i}`,
            alarm: i % 12 == 0 ? 6 : 7,
            value: "/",
            valueDict: {},
          };
        }
      }
      let exp = this.control.config.spotNumbers.split(",");
      for (let i in exp) {
        let range = exp[i].split("-");
        if (range.length == 1) {
          let no = parseInt(range[0]);
          if (spotDict[`${no}`]) results.push(spotDict[`${no}`]);
        } else if (range.length == 2) {
          let start = parseInt(range[0]);
          let end = parseInt(range[1]);
          if (Number.isNaN(start) || Number.isNaN(end)) continue;
          for (let i = start; i <= end; i++) {
            if (spotDict[`${i}`]) results.push(spotDict[`${i}`]);
          }
        }
      }
      this.spots = results;
      if (this.isView) {
        this.getStatus();
        this.getData();
      }
    },
    async getStatus() {
      client.$emit(
        "startBusy",
        "ZhtSpotList.getStatus",
        "正在获取状态,请稍候..."
      );
      await proto.sleep(100);
      try {
        let so = [];
        for (let i in this.spots) {
          let spot = this.spots[i];
          so.push({ uuid: spot.uuid });
        }
        if (so.length) {
          let res = await client.send(proto.MESSAGE_TYPE.statusMessage, {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
              range: "-1",
            },
            so,
          });
          this.brocast(proto.MESSAGE_TYPE.statusMessage, res);
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ZhtSpotList.getStatus");
    },
    async getData() {
      client.$emit(
        "startBusy",
        "ZhtSpotList.getData",
        "正在获取数据,请稍候..."
      );
      await proto.sleep(100);
      try {
        let rtdata = [];
        for (let i in this.spots) {
          let spot = this.spots[i];
          rtdata.push({ uuid: spot.uuid });
        }
        if (rtdata.length) {
          let res = await client.send(proto.MESSAGE_TYPE.realTimeDataMessage, {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
            },
            filterType: 1,
            rtdata,
          });
          this.brocast(proto.MESSAGE_TYPE.realTimeDataMessage, res);
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ZhtSpotList.getData");
    },
    brocast(type, msg) {
      switch (type) {
        case proto.MESSAGE_TYPE.realTimeEventMessage:
          if (msg.rtevent) {
            for (let i in msg.rtevent) {
              let event = msg.rtevent[i];
              for (let i in this.spots) {
                let spot = this.spots[i];
                if (spot.uuid == event.uuid) {
                  spot.alarm = event.stateAlarm;
                  break;
                }
              }
            }
          }
          break;
        case proto.MESSAGE_TYPE.statusMessage:
          if (msg.so) {
            for (let i in msg.so) {
              let status = msg.so[i];
              for (let i in this.spots) {
                let spot = this.spots[i];
                if (spot.uuid == status.uuid) {
                  spot.alarm = status.stateAlarm;
                  break;
                }
              }
            }
          }
          break;
        case proto.MESSAGE_TYPE.realTimeDataMessage:
          if (msg.rtdata) {
            for (let i in msg.rtdata) {
              let data = msg.rtdata[i];
              for (let i in this.spots) {
                let spot = this.spots[i];
                if (spot.uuid == data.uuid) {
                  let value = "/";
                  if (data.sv && data.sv.valueData)
                    value = base64.decode(data.sv.valueData);
                  if (/^[0-9]+\.?[0-9]*$/.test(value)) value = parseFloat(value);
                  if (spot.valueDict[value]) value = spot.valueDict[value];
                  spot.value = value;
                  break;
                }
              }
            }
          }
          break;
      }
    },
  },
};
</script>
